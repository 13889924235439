import { faArrowLeft, faChevronRight, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, Link, navigate } from "gatsby";
import React, { useState } from "react";
import Container from "../components/Container/Container";
import Seo from "../components/Seo/Seo";
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew";
import G from "../images/groenhout_g.png"
import GoogleMapReact from "google-map-react";
import ImageModal from "../components/WoningComponent/ImageGallery/ImageModal/ImageModal";
import WoningContact from "../components/WoningComponent/WoningContact/WoningContact";

const VillaTemplate = ({ data }) => {

    const [slide, setSlide] = useState(0)
    const [modal, setModal] = useState(false)

    const seo = data?.wpVilla.seo
    const title = `${data?.wpVilla.villas.plaats} - ${data?.wpVilla.villas.provincie} `
    const mainImage = data.wpVilla.villas.afbeeldingen[0].afbeelding

    const breadcrumb = seo.breadcrumbs.map((e, i) => (
        <> 
        <span key={i}>
          
          {(i < seo.breadcrumbs.length - 1) ? 
          <>
          <Link to={e.url === '/woningen/' ? '/aanbod/' : e.url} className="mr-2 text-gray"><span>{e.text === `Woningen` ? `Aanbod` : e.text === `Home` ? e.text : title}</span></Link>
          <FontAwesomeIcon icon={faChevronRight} className="mr-2 text-gray" />
          </>
            : <span className="text-gray">{e.text === `Woningen` ? `Aanbod` : e.text === `Home` ? e.text : title}</span>
          }
        </span>
        </>
    ))

    const villa = data.wpVilla.villas
    
    const priceInt = villa.prijs !== '' ? parseInt(villa.prijs) : 0
    const priceCurr = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(priceInt).replace(',00', ',-');

    const price = `${priceCurr}`

    const features = villa.features?.map((f, i)=>{
        return <li key={i} className="capitalize">{f.feature}</li>
    })
    
    const key =  process.env.GATSBY_GOOGLE_API_KEY

    const images = villa.afbeeldingen?.map((a, i)=>{
        return(
            <img key={i} src={a.afbeelding} className="h-full w-full object-cover m-0"></img>
        )
    })

    const imgClickHandler = (e) =>{
        let number = parseInt(e.target.attributes.imageId?.value)
        console.log(number, e.target.attributes)
        setSlide(number)
        setModal(true)
    }

    const modalHandler = () =>{
        setSlide(5)
        setModal(prevModal => !prevModal)
    }

    const imgs = [
        <div className={`image__gallery inline-flex flex-col h-1/2 lg:h-auto ${images.length > 0 ? `lg:w-7/12` : `lg:w-full`} md:w-full p-0 pl-0 row-1 relative`}> 
          {images[0]}
          <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='0'></div>
        </div>,
        <div className={`image__gallery relative lg:grid-rows-2 lg:grid-cols-1 grid-rows-1 grid-cols-2 gap-y-3 gap-x-3 lg:gap-x-0 lg:w-3/12 md:10/12 p-0 lg:px-3 md:px-0 row-2 mt-3 lg:mt-0 ${images.length > 0 ? `grid` : `hidden`} `}>
          <div className="relative">
          {images[1]}
          <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='1'></div>
          </div>
          <div className="relative">
          {images[2]}
          <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='2'></div>
          </div>
          <div className="bg-blue-medium text-white text-xl flex items-center justify-center lg:max-h-1/3 max-h-full h-full absolute lg:top-2/3 md:top-0 lg:mt-2 lg:w-full w-6/12 lg:left-0 left-2/4 lg:ml-0 ml-1 bottom-0 cursor-pointer sm:hidden" onClick={modalHandler}>+ {images.length - 5}
            <div className="absolute h-full w-full" style={{backgroundImage: `url(${G})`,  backgroundSize: '60%', backgroundRepeat: 'no-repeat', backgroundPositionY: 'bottom'}}></div>
          </div>
        </div>,
        <div className={`image__gallery  lg:grid-rows-3 lg:grid-cols-1 md:grid-rows-1 md:grid-cols-3 gap-y-3 md:gap-x-3 lg:gap-x-0 lg:w-2/12 md:w-full lg:mt-0 md:mt-3 p-0 pr-0 row-3 relative ${images.length > 0 ? `sm:grid hidden` : `hidden`}`}>
          <div className="relative">
            {images[3]}
            <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='3'></div>
          </div>
          <div className="relative">
            {images[4]}
            <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='4'></div>
          </div>
          <div>
            {images[5]}
          </div>
          {images.length > 5 ? <div className="bg-blue-medium text-white text-xl flex items-center justify-center lg:max-h-1/3 md:max-h-full absolute lg:top-2/3 md:top-0 lg:mt-2 lg:w-full md:w-4/12 lg:left-0 md:left-2/3 lg:ml-0 md:ml-2 bottom-0 w-full cursor-pointer" onClick={modalHandler}>+ {images.length - 5}
            <div className="absolute h-full w-full" style={{backgroundImage: `url(${G})`,  backgroundSize: '60%', backgroundRepeat: 'no-repeat', backgroundPositionY: 'bottom'}}></div>
          </div> : null}
        </div>
        ]
        
        const allImages = villa.afbeeldingen.map((a, i)=>{
            return(
                <div className="h-full relative">
                    <img key={i} src={a.afbeelding}  className="h-[750px] max-h-[750px] w-2/3 mx-auto object-contain"></img>
                </div>
            )
        })

        const scrollHandler = () => {
            navigate('/contact')
        }

        const lat = parseFloat(villa.locatie?.lat?.replace(/\s/g, ''))
        const long = parseFloat(villa.locatie?.long?.replace(/\s/g, ''))

        const desc = villa.omschrijving?.replaceAll('&#13;&#13;', "")?.replaceAll('&amp;nbsp;', "")?.replaceAll("&amp;hellip;", "");
        
    return(
        <>
            <Seo title={seo.title} description={seo.metaDesc}></Seo>
            <SmallHeroNew title={title} image={mainImage} overlay={true} overlayImage={G}></SmallHeroNew>
            <Container styles={'my-16 lg:px-0 px-8'}>
                <div className="image__gallery flex lg:flex-row flex-col w-full mx-0 flex-wrap h-auto mb-5" >
                    {imgs}
                </div>
                <div className={`modal w-screen h-screen bg-white fixed flex left-0 top-0 justify-center items-center z-40 ${modal ? `block` : `hidden`}`}>
                    <ImageModal images={allImages} slideIndex={slide} click={modalHandler} />
                </div>
                <div className="flex lg:flex-row flex-col lg:items-center md:items-left mb-12 w-full lg:mx-0">
                    <div className="lg:w-1/12 sm:w-2/12 w-4/12">
                        <div onClick={()=>{navigate(-1)}} className="cursor-pointer"><span className="text-blue block p-3 pt-2 pb-1 lg:mb-0 md:mb-4 flex flex-row justify-left items-center"><FontAwesomeIcon icon={faArrowLeft} /><span className="w-full ml-5">Terug</span></span></div>
                    </div>
                    <div className="lg:ml-10 lg:mt-0 mt-4 mb-0 flex flex-row items-center">{breadcrumb}</div>
                </div>
            </Container>

            <Container styles={'lg:px-0 px-8'}>
                <div className="flex lg:flex-row flex-col">
                    <div className="xl:w-9/12 lg:w-8/12 w-full lg:mx-0">
                    <h2 className="text-blue mb-0">{villa.plaats}</h2> 
                    <p className="text-gray mt-1 mb-4"><span>{villa.provincie}</span></p>
                    <div className="grid xl:grid-cols-3 grid-cols-2 lg:gap-8 gap-2 w-full lg:my-8 my-8">
                            {villa.oppervlakten.bouw !== null ?
                            <div className="bg-blue text-light w-full p-4 md:p-8 flex flex-col align-center justify-center break-words">
                                <span className="font-bold">Woonoppervlak</span>
                                <p className="m-0 mt-1 text-sm text-light">{villa.oppervlakten.bouw}m²</p>
                            </div>
                            : null}

                            {villa.bedden !== null ?
                            <div className="bg-light text-blue w-full p-4 md:p-8 flex flex-col align-center justify-center break-words">
                                <span className="font-bold">Aantal kamers</span>
                                <p className="m-0 mt-1 text-sm text-gray">{villa.bedden} slaapkamers </p>
                            </div>
                            : null}

                            {villa.badkamers !== null ?
                            <div className="bg-light text-blue w-full p-4 md:p-8 flex flex-col align-center justify-center break-words">
                                <span className="font-bold">Aantal badkamers</span>
                                <p className="m-0 mt-1 text-sm text-gray" style={{textTransform: "capitalize"}}>{villa.badkamers}</p>
                            </div>
                            : null}
                    </div>
                    {villa.omschrijving ? <div className="p-5 bg-light text-blue my-5 text-sm" dangerouslySetInnerHTML={{__html: desc }}></div> : null}
                    <h3 className="text-blue">{price}</h3>
                    {villa.features !== null ? 
                        <h3 className="text-blue">Eigenschappen</h3>
                     : null}
                    {villa.features !== null ? 
                    <ul className="ml-4 list-disc">
                        {features}
                    </ul>
                    : null}
                    </div>
                    <div className="xl:w-3/12 lg:w-4/12 lg:ml-8 md:w-8/12 w-full lg:mx-0">
                        <WoningContact seller={[]} image={villa.afbeeldingen[0].afbeelding !== undefined ? villa.afbeeldingen[0].afbeelding : ''} scrollHandler={scrollHandler} contactpersoon={null} vestiging={''} origin={1} button={'Contact'}  />
                    </div>
                </div>
                <div>
                   
                </div>
            </Container>

            {
                villa.locatie.lat == undefined  ||  villa.locatie.long == undefined ||  villa.locatie.lat == null ||  villa.locatie.long == null  ?
                    null
                :
                    <div className="w-full bg-light lg:mt-24 mt-16" id="bijlagen" style={{maxHeight: 400}}>
                        
                            <div className="flex lg:flex-row flex-col justify-between h-full">
                            <div className="xl:w-full lg:w-full w-full lg:mx-0 mx-auto h-full relative map__style">
                                <div className="w-full h-full map__style" style={{height: 400}}>
                                    <GoogleMapReact bootstrapURLKeys={{ key: key }} center={{lat: lat, lng: long}} defaultZoom={16}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} lat={lat} lng={long} size="3x" className="text-red" />
                                    </GoogleMapReact>
                                </div>
                            </div>
                            
                            </div>
                        
                    </div>
            }
        </>
    )
 }

 export default VillaTemplate;

 export const villaQuery = graphql`
  query($id: Int!){
   wpVilla(databaseId: { eq: $id }){
        seo {
            metaDesc
            title
            breadcrumbs {
            text
            url
            }
        }
       title
       villas {
        plaats
        prijs
        provincie
        zwembad
        locatieDetails
        bedden
        badkamers
        omschrijving
        oppervlakten {
          bouw
          perceel
        }
        locatie {
          lat
          long
        }
        features {
          feature
        }
        afbeeldingen {
          afbeelding
        }
      }
   }
  }
`
